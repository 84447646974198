import React from "react";

const JobsByIndustry = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Industry</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_40" />
            Hospitality <span>2</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_31" />
            AutoMobile <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_41" />
            Courier/Logistics <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_28" />
            Electronics <span>2</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_1" />
            Information Technology <span>8</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_7" />
            Advertising/PR <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_24" />
            Travel/Tourism/Transportation <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_3" />
            Banking/Financial Services <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_11" />
            Education/Training <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="industry_38" />
            Health &amp; Fitness <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">
          View More
        </button>{" "}
      </div>
    </>
  );
};

export default JobsByIndustry;
