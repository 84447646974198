import React from "react";
import { BsPuzzleFill } from "react-icons/bs";

const JobSkills = () => {
  return (
    <>
      <div className="jbDetailBox">
        <div className="jbDetailHeader skillsRequired">
          <h5>
            <BsPuzzleFill /> Skills Required
          </h5>
          <ul>
            <li>
              <a href="#">Adobe Photoshop</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default JobSkills;
