import React, { useEffect } from "react";
import BlogAside from "../blogs/blog-aside/BlogAside";
import BlogDetailContent from "./blog-detail-content/BlogDetailContent";

const BlogDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <BlogDetailContent />
            </div>
            <div className="col-md-4">
              <BlogAside />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
