import React, { useEffect } from "react";
import About from "../../components/about/About";
import PageSearch from "../../components/page-search/PageSearch";

const AboutPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(true);
  }, []);
  return (
    <>
      <PageSearch />
      <About />
    </>
  );
};

export default AboutPage;
