import React from "react";
import { FiSearch } from "react-icons/fi";
import BlogCategories from "./blog-categories/BlogCategories";

const BlogAside = () => {
  return (
    <>
      <div className="blogAside">
        <div className="blogAsideBox">
          <div className="searchBlog">
            <input
              type="text"
              name="search"
              className="form-control"
              placeholder="Search"
              autoComplete="off"
            />
            <button type="button" className="btn">
              <FiSearch />
            </button>
          </div>
        </div>
        <BlogCategories />
      </div>
    </>
  );
};

export default BlogAside;
