import React from "react";
import video from "../../../assets/videos/alumni.mp4";

const JobVideo = () => {
  return (
    <>
      <div className="videoWrapper sectionPD">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="embed-responsive embed-responsive-16by9">
                <video width="90%" height={350} controls autoPlay muted>
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="jobVideoText">
                <div className="sectionTitle">
                  <h2>
                    Watch Our <span>Video</span> 
                  </h2>
                </div>
                {/* title end */}
                <p>
                  Our partners make Milestone products more dynamic and
                  integrations push the limits of what is possible. XProtect®
                  software protects animals from known poachers and protects the
                  city of Minneapolis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobVideo;
