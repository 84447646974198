import React from "react";

const JobType = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Job Type</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_type_3" />
            Full Time/Permanent <span>8</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_type_1" />
            Contract <span>7</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_type_2" />
            Freelance <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_type_4" />
            Internship <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_type_5" />
            Part Time <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">View More</button>{" "}
      </div>
    </>
  );
};

export default JobType;
