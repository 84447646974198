import React from "react";
import { FiSearch } from "react-icons/fi";
const SalaryRange = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Salary Range</h4>
        <div className="form-group mb-3 ">
          <input
            className="form-control"
            placeholder="Salary From"
            name="salary_from"
            type="number"
          />
        </div>
        <div className="form-group mb-3">
          <input
            className="form-control"
            placeholder="Salary To"
            name="salary_to"
            type="number"
          />
        </div>
        <div className="form-group mb-3">
          <select
            className="form-control"
            name="salary_currency"
          >
            <option defaultValue="selected">
              Select Salary Currency
            </option>
            <option value="AED">AED</option>
            <option value="AF">AF</option>
            <option value="ALL">ALL</option>
            <option value="ANG">ANG</option>
            <option value="ARS">ARS</option>
            <option value="AUD">AUD</option>
            <option value="AWG">AWG</option>
            <option value="AZ">AZ</option>
            <option value="BAM">BAM</option>
            <option value="BBD">BBD</option>
            <option value="BG">BG</option>
            <option value="BMD">BMD</option>
            <option value="BOB">BOB</option>
            <option value="BRL">BRL</option>
            <option value="BWP">BWP</option>
            <option value="BYR">BYR</option>
            <option value="CAD">CAD</option>
            <option value="CHF">CHF</option>
            <option value="CLP">CLP</option>
            <option value="CNY">CNY</option>
            <option value="COP">COP</option>
            <option value="CRC">CRC</option>
            <option value="CUP">CUP</option>
            <option value="CZK">CZK</option>
            <option value="DKK">DKK</option>
            <option value="DOP ">DOP </option>
            <option value="EGP">EGP</option>
            <option value="EUR">EUR</option>
            <option value="FKP">FKP</option>
            <option value="GBP">GBP</option>
            <option value="GHC">GHC</option>
            <option value="GIP">GIP</option>
            <option value="GTQ">GTQ</option>
            <option value="GYD">GYD</option>
            <option value="HNL">HNL</option>
            <option value="HUF">HUF</option>
            <option value="IDR">IDR</option>
            <option value="ILS">ILS</option>
            <option value="INR">INR</option>
            <option value="IRR">IRR</option>
            <option value="ISK">ISK</option>
            <option value="JEP">JEP</option>
            <option value="JMD">JMD</option>
            <option value="JPY">JPY</option>
            <option value="KGS">KGS</option>
            <option value="KHR">KHR</option>
            <option value="KYD">KYD</option>
            <option value="KZT">KZT</option>
            <option value="LAK">LAK</option>
            <option value="LBP">LBP</option>
            <option value="LKR">LKR</option>
            <option value="LRD">LRD</option>
            <option value="LTL">LTL</option>
            <option value="LVL">LVL</option>
            <option value="MKD">MKD</option>
            <option value="MNT">MNT</option>
            <option value="MUR">MUR</option>
            <option value="MX">MX</option>
            <option value="MYR">MYR</option>
            <option value="MZ">MZ</option>
            <option value="NAD">NAD</option>
            <option value="NG">NG</option>
            <option value="NIO">NIO</option>
            <option value="NOK">NOK</option>
            <option value="NPR">NPR</option>
            <option value="NZD">NZD</option>
            <option value="OMR">OMR</option>
            <option value="PAB">PAB</option>
            <option value="PE">PE</option>
            <option value="PHP">PHP</option>
            <option value="PKR">PKR</option>
            <option value="PL">PL</option>
            <option value="PYG">PYG</option>
            <option value="QAR">QAR</option>
            <option value="RO">RO</option>
            <option value="RSD">RSD</option>
            <option value="RUB">RUB</option>
            <option value="SAR">SAR</option>
            <option value="SBD">SBD</option>
            <option value="SCR">SCR</option>
            <option value="SEK">SEK</option>
            <option value="SGD">SGD</option>
            <option value="SHP">SHP</option>
            <option value="SOS">SOS</option>
            <option value="SRD">SRD</option>
            <option value="SVC">SVC</option>
            <option value="SYP">SYP</option>
            <option value="THB">THB</option>
            <option value="TRY">TRY</option>
            <option value="TTD">TTD</option>
            <option value="TVD">TVD</option>
            <option value="TWD">TWD</option>
            <option value="UAH">UAH</option>
            <option value="USD">USD</option>
            <option value="UYU">UYU</option>
            <option value="UZS">UZS</option>
            <option value="VEF">VEF</option>
            <option value="VND">VND</option>
            <option value="YER">YER</option>
            <option value="ZAR">ZAR</option>
            <option value="ZWD">ZWD</option>
          </select>
        </div>
        {/* Salary end */}
        {/* button */}
        <div className="search">
          <button type="button" className="btn pageBtn d-block w-100">
            <FiSearch /> Search Jobs
          </button>
        </div>
        {/* button end*/}
      </div>
    </>
  );
};

export default SalaryRange;
