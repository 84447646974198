import React from "react";

const JobsByGender = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Gender</h4>
        <ul className="optionList view_more_ul">
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="gender_2" />
            Male <span>11</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="gender_1" />
            Female <span>3</span>{" "}
          </li>
        </ul>
      </div>
    </>
  );
};

export default JobsByGender;
