import React from "react";
import { AiFillFileText } from "react-icons/ai";

const JobDesc = () => {
  return (
    <>
      <div className="jbDetailBox">
        <div className="jbDetailHeader">
          <h5>
            <AiFillFileText /> Job Description
          </h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium
            nunc non justo placerat pulvinar. Vestibulum ac ullamcorper sapien,
            nec scelerisque ipsum. Aliquam in tempus nulla. Curabitur ac
            pulvinar elit. Donec sed iaculis lorem. Duis at fermentum odio, ut
            mattis risus. Mauris molestie mi a dignissim eleifend. Nam sit amet
            facilisis odio, ac ornare quam. Donec a arcu at leo interdum viverra
            vulputate vitae odio. Aliquam erat volutpat. Vivamus aliquam
            interdum ex a condimentum. Sed mollis maximus cursus. Aenean vitae
            malesuada tellus. Aenean tristique porta massa. Fusce at nisl vitae
            dui consectetur pharetra. Praesent non ipsum dui.
          </p>
        </div>
      </div>
    </>
  );
};

export default JobDesc;
