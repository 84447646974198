import React from "react";

const JobsByCompany = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Company</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_9" />
            Abaris Softech Pvt Ltd <span>6</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_5" />
            Power Wave <span>2</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_8" />
            Travel Advisor <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_4" />
            Surf Wave <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_1" />
            Hotel Tirupati Classic <span>2</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_3" />
            New Design Studio <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_11" />
            Sphere <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_7" />
            Connect People <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_10" />
            Net Design <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_6" />
            Media Wave <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="company_15" />
            Pharma Tech Inc. <span>1</span>
          </li>
          <li>
            <input
              type="checkbox"
              name="company_id[]"
              id="company_14"
              defaultValue={14}
            />
            <label htmlFor="company_14" />
            Mayan Design Studios <span>1</span>
          </li>
        </ul>
        <button type="button" className="btn view_more">
          View More
        </button>{" "}
      </div>
    </>
  );
};

export default JobsByCompany;
