import React from 'react'
import FeaturedCompany from './featured-company/FeaturedCompany'

const FeaturedCompanies = () => {
  return (
    <>
        <section className="featuredCompanies sectionPD">
            <div className="container">
                <div className="sectionTitle">
                    <h2>Featured Companies</h2>
                    <FeaturedCompany />
                </div>
            </div>
        </section>
    </>
  )
}

export default FeaturedCompanies