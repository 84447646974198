import React from "react";

const TermsConditions = () => {
  return (
    <>
      <section className="about sectionPD">
        <div className="container">
          <div className="aboutInfo">
            <h1>Terms Of Use</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              eleifend posuere magna vel suscipit. Interdum et malesuada fames
              ac ante ipsum primis in faucibus. Morbi hendrerit imperdiet ex
              quis rutrum. Phasellus mattis mauris tincidunt, facilisis tortor
              ut, tincidunt justo. Duis interdum metus libero, vitae finibus
              quam bibendum a. Duis est nisi, rutrum id purus id, condimentum
              rutrum nulla. Suspendisse sodales cursus dolor, ac vehicula elit
              semper eget. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Aenean bibendum leo metus, non sagittis arcu pharetra quis.
              Nullam sollicitudin ultricies lectus, id tincidunt odio. In hac
              habitasse platea dictumst. Nunc in nisi nibh. Ut dignissim ex id
              iaculis interdum. Donec ullamcorper nec orci a ullamcorper. Mauris
              volutpat tincidunt tellus, vitae sagittis urna pharetra pharetra.
            </p>
            <p>
              Curabitur fermentum, massa a finibus porttitor, felis lorem congue
              lacus, imperdiet sollicitudin nisl tortor a magna. Phasellus eget
              arcu sed elit sagittis pellentesque. Sed sit amet mattis lacus.
              Suspendisse quis vehicula lorem, eget ullamcorper neque. Aliquam
              vitae sapien erat. Vestibulum eget mauris sit amet turpis dapibus
              dignissim et ac libero. Etiam vulputate, ex vel dictum volutpat,
              arcu arcu varius massa, a pulvinar velit justo non ligula. Donec a
              metus sit amet purus fermentum pellentesque. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos.
            </p>
            <p>
              Nam aliquet odio vitae finibus auctor. Etiam lorem dui,
              sollicitudin placerat felis quis, molestie posuere eros. Nunc id
              tellus quis ligula iaculis tempor. Etiam tincidunt augue diam, et
              faucibus lacus rhoncus id. Integer eu lectus at sem sollicitudin
              dapibus. Cras blandit urna sit amet nulla fermentum, in pulvinar
              mi tempor. Nam mollis justo arcu, non rutrum orci pharetra vitae.
              Duis eleifend quam ac arcu posuere imperdiet. Suspendisse eget
              viverra lectus. Sed laoreet vestibulum posuere. Phasellus dictum
              ultrices egestas.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;
