import React from "react";

import { AiFillLock } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";

const CandidateEmployer = () => {
  return (
    <>
      <div className="userLogin">
        <form className="loginForm">
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Name"
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <input
              type="mail"
              className="form-control"
              name="mail"
              placeholder="Email"
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Confirm password"
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <div className="checkField">
              <input type="checkbox" className="form-check-input" />
              <span> Subscribe to Newsletter</span>
            </div>
            <div className="checkField">
              <input type="checkbox" className="form-check-input" />
              <span> I accept Terms of Use</span>
            </div>
          </div>

          <button type="button" className="btn pageBtn w-100">
            Register
          </button>
        </form>
        <div className=" forgotPass newUser mt-4">
          <FaUserPlus /> <span>Have account?</span>
          <Link to="/login">Sign in</Link>
        </div>
      </div>
    </>
  );
};

export default CandidateEmployer;
