import React, { useEffect } from "react";
import CompanyDetail from "../../components/featured-companies/company-detail/CompanyDetail";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";

const CompanyDetailPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(true);
  }, []);
  return (
    <>
      <Breadcrumb title="Company detail" />
      <CompanyDetail />
    </>
  );
};

export default CompanyDetailPage;
