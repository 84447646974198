import React from "react";

const BlogDetailContent = () => {
  return (
    <>
      <div className="blogInfo blogInner">
        <div className="postImg">
          <img
            src="https://hsalghanimdelivery.com/uploads/blogs/blog-1_450_498.jpg"
            className="img-fluid"
          />
        </div>
        <div className="postContent">
          <div className="postHeader">
            <h4>How you can get job?</h4>
            <div className="postMeta">
              Category : <a href="#">Jobseekers</a>, <a href="#">General</a>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum risus diam, consectetur pellentesque tempus id,
              scelerisque vitae odio. Mauris quis urna sit amet est faucibus
              dignissim sit amet in eros. Morbi fringilla eleifend condimentum.
              Aliquam eu lectus eget odio venenatis efficitur. Nam ultrices
              egestas lacus. Vestibulum quis dolor libero. In congue, mi et
              bibendum vestibulum, nisl urna accumsan mauris, ultrices mollis
              mauris mi et nulla.
            </p>
            <p>
              Vestibulum vel nibh et erat faucibus vulputate. Aenean dictum erat
              at libero tempor feugiat. Vivamus in velit ac nisi tempor
              volutpat. Aliquam efficitur purus rhoncus ornare dictum. Nam eu
              sagittis tortor. Interdum et malesuada fames ac ante ipsum primis
              in faucibus. Proin quis purus diam. Vestibulum turpis tortor,
              sodales sed dui vel, fringilla tincidunt ex. Praesent tristique
              justo sit amet dolor sollicitudin tempor. In suscipit efficitur
              nunc, in pulvinar libero aliquam eget.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailContent;
