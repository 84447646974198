import React from "react";
import { AiFillHome } from "react-icons/ai";
import { SiGmail } from "react-icons/si";
import { MdAddIcCall } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";

const ContactAddress = () => {
  return (
    <>
      <div className="contactAddress">
        <div className="contactAdd">
          <div className="icon">
            <AiFillHome />
          </div>
          <div className="information">
            <strong>Address:</strong>
            <p>
              Saket, New Delhi, office No23 Website
            </p>
          </div>
        </div>
        <div className="contactAdd">
          <div className="icon">
            <SiGmail />
          </div>
          <div className="information">
            <strong>Email Address::</strong>
            <p>
              <a href="mailto:info@jobs.ind.do">
                info@jobs.ind.do
              </a>
            </p>
          </div>
        </div>
        <div className="contactAdd">
          <div className="icon">
            <MdAddIcCall />
          </div>
          <div className="information number">
            <strong>Phone:</strong>
            <p>
              <a href="tell:info@jobs.ind.do">
                <BiChevronRight /> +918851746286
              </a>
            </p>
            <p>
              <a href="tell:info@jobs.ind.do">
                <BiChevronRight /> +918851741234
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactAddress;
