import React, { useEffect } from "react";
import AboutCompany from "./about-company/AboutCompany";
import CandidateInfo from "./candidate-info/CandidateInfo";
import CompanyDetailInfo from "./company-detail-info/CompanyDetailInfo";
import JobOpenings from "./job-openings/JobOpenings";

const CompanyDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CandidateInfo />
      <section className="jobDetailInfo">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="companyLeft">
                <AboutCompany />
                <JobOpenings />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="companyRight">
                <CompanyDetailInfo />
                <div className="jbDetailBox">
                  <div className="jbDetailHeader">
                    <h5>Google Map</h5>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d224283.63882101112!2d77.25971!3d28.556792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1680087522426!5m2!1sen!2sin"
                      width="100%"
                      height={200}
                      style={{ border: 0 }}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyDetail;
