import React from "react";
import { Link } from "react-router-dom";

const LatestBlog = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="blogInner">
          <div className="postImg">
            <img
              src="https://hsalghanimdelivery.com/uploads/blogs/blog-3_532.jpg"
              className="img-fluid"
            />
          </div>
          <div className="postContent">
            <div className="postHeader">
              <h4>
                <Link to="/blog-detail">Mauris sit amet augue in dolor</Link>
              </h4>
              <div className="postMeta">
                Category : <a href="#">Jobseekers</a>, <a href="#">Employers</a>
                , <a href="#">Web Designer</a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum risus diam, consectetur pellentesque tempus id,
                scelerisque vitae odio. Mauris...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="blogInner">
          <div className="postImg">
            <img
              src="https://hsalghanimdelivery.com/uploads/blogs/blog-2_189.jpg"
              className="img-fluid"
            />
          </div>
          <div className="postContent">
            <div className="postHeader">
              <h4>
                <Link to="/blog-detail">
                  Vestibulum vel nibh et erat faucibus
                </Link>
              </h4>
              <div className="postMeta">
                Category : <a href="#">Jobseekers, </a>, <a href="#">General</a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum risus diam, consectetur pellentesque tempus id,
                scelerisque vitae odio. Mauris...
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="blogInner">
          <div className="postImg">
            <img
              src="https://hsalghanimdelivery.com/uploads/blogs/blog-1_450_498.jpg"
              className="img-fluid"
            />
          </div>
          <div className="postContent">
            <div className="postHeader">
              <h4>
                <Link to="/blog-detail">How you can get job?</Link>
              </h4>
              <div className="postMeta">
                Category : <a href="#">Jobseekers, </a>, <a href="#">General</a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum risus diam, consectetur pellentesque tempus id,
                scelerisque vitae odio. Mauris...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlog;
