import React, { useEffect } from "react";
import Register from "../../components/register/Register";

const RegisterPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(false);
  }, []);
  return (
    <>
      <Register />
    </>
  );
};

export default RegisterPage;
