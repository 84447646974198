import React, { useEffect } from 'react'
import JobPageTitle from '../../components/job-detail/job-age-title/JobPageTitle'
import JobDetail from '../../components/job-detail/JobDetail'
import PageSearch from '../../components/page-search/PageSearch'

const JobDetailPage = ({setCommon}) => {
    useEffect(()=>{
      setCommon(true)
    }, [])
  return (
    <>
        <PageSearch />
        <JobPageTitle />
        <JobDetail />
    </>
  )
}

export default JobDetailPage