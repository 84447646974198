import React from "react";

const JobsBySkill = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Skill</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_15" />
            MySQL <span>4</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_16" />
            PHP <span>5</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_18" />
            Strong Communication skills <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_2" />
            Adobe Photoshop <span>5</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_6" />
            CSS <span>8</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_8" />
            HTML <span>8</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_10" />
            JavaScript <span>5</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_11" />
            Jquery <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_19" />
            WordPress <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_5" />
            Communication Skills <span>3</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_12" />
            Marketing <span>3</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_17" />
            Sales <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_1" />
            Adobe Illustrator <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_7" />
            English Fluency <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_4" />
            COMMUNICATION <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="job_skill_3" />
            Cold Calling <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">
          View More
        </button>{" "}
      </div>
    </>
  );
};

export default JobsBySkill;
