import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="companyLeftBox">
        <div className="companyInnerBox">
          <h5>About Company</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            fermentum condimentum mauris, non posuere urna consectetur quis.
            Suspendisse semper eu eros eget convallis. Etiam mattis blandit
            nulla, non venenatis risus varius vel. Morbi fringilla dignissim
            elit id blandit. Pellentesque vel luctus felis. Vestibulum eros
            nibh, consequat ut felis in, vehicula lobortis quam. Duis diam
            mauris, convallis in risus in, gravida hendrerit lacus. Donec
            euismod accumsan sem et aliquam. Duis a velit eget urna mattis
            ultricies. Aliquam nibh ipsum, aliquet nec sollicitudin non,
            fermentum nec diam. Vestibulum ac urna vehicula, dapibus dui quis,
            aliquet neque. Sed ac tristique purus. Vestibulum tempor, erat eu
            porta tempor, erat ipsum cursus dui, eu tempus mauris leo id mi. Sed
            ultrices sollicitudin vehicula. Proin in ullamcorper massa.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
