import React from "react";

const JobsByExperience = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Experience</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_5" />4 Year <span>3</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_3" />2 Year <span>3</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_6" />5 Year <span>5</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_4" />3 Year <span>4</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_8" />7 Year <span>2</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_7" />6 Year <span>1</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_experience_11" />
            Fresh <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">View More</button>{" "}
      </div>
    </>
  );
};

export default JobsByExperience;
