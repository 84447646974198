import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import facebook from "../../assets/images/social/facebook.png";
import twitter from "../../assets/images/social/twitter.png";
import { AiFillLock } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";

const Login = () => {
  return (
    <>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="loginInfo">
                <Tabs
                  defaultActiveKey="candidate"
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="candidate" title="Candidate">
                    <div className="userLogin">
                      <div className="socialLoginBox">
                        <h4>Login with Social</h4>
                        <div className="socialIcons">
                          <a href="#">
                            <img
                              src={facebook}
                              alt="Facebook"
                              className="img-fluid"
                            />
                          </a>
                          <a href="#">
                            <img
                              src={twitter}
                              alt="Twitter"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="loginDivider">
                        <span>Or login with your account</span>
                      </div>

                      <form className="loginForm">
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="example@gmail.com"
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your password"
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-3">
                          <div className="forgotPass">
                            <AiFillLock /> <span>Forgot Your Password?</span>
                            <Link to="/" className="text-decoration-underline">
                              Click here
                            </Link>
                          </div>
                        </div>

                        <button type="button" className="btn pageBtn w-100">
                          Login
                        </button>
                      </form>
                      <div className=" forgotPass newUser mt-4">
                        <FaUserPlus /> <span>New User?</span>
                        <Link to="#">Register Here</Link>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="employer" title="Employer">
                    <div className="userLogin">
                      <div className="socialLoginBox">
                        <h4>Login with Social</h4>
                        <div className="socialIcons">
                          <a href="#">
                            <img
                              src={facebook}
                              alt="Facebook"
                              className="img-fluid"
                            />
                          </a>
                          <a href="#">
                            <img
                              src={twitter}
                              alt="Twitter"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="loginDivider">
                        <span>Or login with your account</span>
                      </div>

                      <form className="loginForm">
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="example@gmail.com"
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your password"
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-3">
                          <div className="forgotPass">
                            <AiFillLock /> <span>Forgot Your Password?</span>
                            <Link to="/" className="text-decoration-underline">
                              Click here
                            </Link>
                          </div>
                        </div>

                        <button type="button" className="btn pageBtn w-100">
                          Login
                        </button>
                      </form>
                      <div className=" forgotPass newUser mt-4">
                        <FaUserPlus /> <span>New User?</span>
                        <Link to="#">Register Here</Link>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="loginImage">
                <img
                  src="https://hsalghanimdelivery.com/images/login-page-banner.png"
                  alt="Login"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
