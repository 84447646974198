import React from "react";

const GetStarted = () => {
  return (
    <>
      <section className="getStarted sectionPD">
        <div className="container">
          <div className="getStart">
            <h3>Are You Looking For Job! </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nunc ex, maximus vel felis ut, vestibulum tristique enim. Proin eu
              nulla est. Maecenas tempor euismod suscipit. Sed at libero ante.
              Vestibulum nec odio lacus.
            </p>
            <button className="btn pageBtn">Get Started Today</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetStarted;
