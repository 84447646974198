import React from "react";
import RatingUser from "./rating-user/RatingUser";

const ClientSays = () => {
  return (
    <>
      <div className="col-lg-6">
        <div className="clientSays">
          <RatingUser />
          <h4>John Doe</h4>
          <strong>PM, Google.com</strong>
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            pretium nunc non justo placerat pulvinar. Vestibulum ac ullamcorper
            sapien, nec scelerisque ipsum. Aliquam in tempus nulla. Curabitur ac
            pulvinar elit. Donec sed iaculis lorem."
          </p>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="clientSays">
          <RatingUser />
          <h4>Garry Miller Jr</h4>
          <strong>CEO - Gates Inc</strong>
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            pretium nunc non justo placerat pulvinar. Vestibulum ac ullamcorper
            sapien, nec scelerisque ipsum. Aliquam in tempus nulla. Curabitur ac
            pulvinar elit. Donec sed iaculis lorem."
          </p>
        </div>
      </div>
    </>
  );
};

export default ClientSays;
