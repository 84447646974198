import React from "react";

const JobByCountry = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Country</h4>
        <ul className="optionList view_more_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="country_231" />
            United States of America <span>16</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="country_117" />
            Kuwait <span>2</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="country_226" />
            Tuvalu <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">View More</button>{" "}
      </div>
    </>
  );
};

export default JobByCountry;
