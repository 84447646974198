import React from "react";
import RelatedJob from "./related-job/RelatedJob";

const RelatedJobs = () => {
  return (
    <>
      <div className="relatedJobs">
        <h5>Related Jobs</h5>
        <RelatedJob />
      </div>
    </>
  );
};

export default RelatedJobs;
