import React from "react";
import Menus from "./menus/Menus";
import logo from "../../../../assets/images/logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import { MdLanguage } from "react-icons/md";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <>
      
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link className="navbar-brand brand" to="/">
            <img src={logo} alt="Logo" className="img-fluid" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse menus"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Menus />
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <MdLanguage />
                  {/* <div className="langBtn">
                    <img
                      src="https://hsalghanimdelivery.com/images/lang.png"
                      alt="Lang"
                      className="img-fluid"
                    />
                  </div> */}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdownMenu">
                  <Dropdown.Item href="#/action-1">عربى</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">English</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Español</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">اردو</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
