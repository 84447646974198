import React from "react";
import BlogAside from "./blog-aside/BlogAside";
import Blog from "./blog/Blog";

const Blogs = () => {
  return (
    <>
      <section className="sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <Blog />
              </div>
            </div>
            <div className="col-md-4">
                <BlogAside />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
