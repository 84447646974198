import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BackTop from "./components/back-top/BackTop";
import Footer from "./components/common/footer/Footer";
import Header from "./components/common/header/Header";
import Preloader from "./components/preloader/Preloader";
import AboutPage from "./pages/about";
import BlogPage from "./pages/blog";
import BlogDetailPage from "./pages/blog-detail";
import CompaniesPage from "./pages/companies";
import CompanyDetailPage from "./pages/company-detail";
import ContactPage from "./pages/contact";
import HomePage from "./pages/home";
import JobDetailPage from "./pages/job-detail";
import JobsPage from "./pages/jobs";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import TermsConditionsPage from "./pages/terms-conditions";

function App() {
  const [common, setCommon] = useState(true);
  const [preloader, setPreloader] = useState(true);
  
  useEffect(() => {
    setTimeout(() => {
      setPreloader(false);
    }, 1000);
  }, []);
  return (
    <>
      {preloader && <Preloader />}
      <BackTop />
      {common && <Header />}
      <Routes>
        <Route path="/" element={<HomePage setCommon={setCommon} />} />
        <Route path="/login" element={<LoginPage setCommon={setCommon} />} />
        <Route path="/register" element={<RegisterPage setCommon={setCommon} />} />

        <Route path="/jobs" element={<JobsPage setCommon={setCommon} />} />
        <Route
          path="/job-detail"
          element={<JobDetailPage setCommon={setCommon} />}
        />
        <Route
          path="/companies"
          element={<CompaniesPage setCommon={setCommon} />}
        />
        <Route path="/about" element={<AboutPage setCommon={setCommon} />} />
        <Route
          path="/contact"
          element={<ContactPage setCommon={setCommon} />}
        />
        <Route path="/blog" element={<BlogPage setCommon={setCommon} />} />
        <Route
          path="/blog-detail"
          element={<BlogDetailPage setCommon={setCommon} />}
        />
        <Route
          path="/terms-conditions"
          element={<TermsConditionsPage setCommon={setCommon} />}
        />
        <Route
          path="/company-detail"
          element={<CompanyDetailPage setCommon={setCommon} />}
        />
      </Routes>
      {common && <Footer />}
    </>
  );
}

export default App;
