import React from "react";
import { HiArrowSmRight } from "react-icons/hi";
import { BsSendFill } from "react-icons/bs";

const JobPageTitle = () => {
  return (
    <>
      <div className="jobPageTitle">
        <div className="container">
          <div className="jobPageInfo">
            <div className="jobTitle">
              <h5>Full Stack Developer Required - Abaris Softech Pvt Ltd</h5>
              <div className="text">Date Posted: Oct 20, 2019</div>
              <a href="#">
                <HiArrowSmRight /> Login to View Salary
              </a>
            </div>

            <div className="jobButtons applyBox">
              <a href="#" className="btn pageBtn apply">
                <BsSendFill /> Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPageTitle;
