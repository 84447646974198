import React from "react";

const PageDataLength = () => {
  return (
    <>
      <div className="topStatInfo">Showing Jobs : 1 - 16 Total 19</div>
    </>
  );
};

export default PageDataLength;
