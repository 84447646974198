import React, { useEffect } from "react";
import FeaturedCompanies from "../../components/featured-companies/FeaturedCompanies";
import { FiSearch } from "react-icons/fi";
const CompaniesPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(true);
  }, []);
  return (
    <>
      <div className="pageSearch company">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h4 className="mb-3">
                Browse Companies.
                <span className="d-block fs-5">
                  Get hired in most high rated companies.
                </span>
              </h4>
              <div className="searchForm">
                <div className="input-group">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="keywords e.g. 'Google' "
                    autoComplete="off"
                  />
                  <button type="button" className="btn searchBtn">
                    {" "}
                    <FiSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeaturedCompanies />
    </>
  );
};

export default CompaniesPage;
