import React from "react";
import PopularIndustry from "./popular-industry/PopularIndustry";

const PopularIndustries = () => {
  return (
    <>
      <div className="popularInd">
        <h4>Popular Industries</h4>
        <ul className="popularIndusList">
            <PopularIndustry />          
        </ul>
      </div>
    </>
  );
};

export default PopularIndustries;
