import React, { useEffect } from 'react'
import BlogDetail from '../../components/blog-detail/BlogDetail'
import Breadcrumb from '../../components/shared/breadcrumb/Breadcrumb'

const BlogDetailPage = ({ setCommon }) => {
    useEffect(() => {
      setCommon(true);
    }, []);
  return (
    <>  
        <Breadcrumb title="blog detail" />
        <BlogDetail />
    </>
  )
}

export default BlogDetailPage