import React from "react";

const PopularIndustry = () => {
  return (
    <>
      <li>
        <a href="#" title="Information Technology">
          Information Technology
        </a>
      </li>
      <li>
        <a href="#" title="Hospitality">
          Hospitality
        </a>
      </li>
      <li>
        <a href="#" title="Electronics">
          Electronics
        </a>
      </li>
      <li>
        <a href="#" title="AutoMobile">
          AutoMobile
        </a>
      </li>
      <li>
        <a href="#" title="Courier/Logistics">
          Courier/Logistics
        </a>
      </li>
      <li>
        <a href="#" title="Advertising/PR">
          Advertising/PR
        </a>
      </li>
      <li>
        <a href="#" title="Travel/Tourism/Transportation">
          Travel/Tourism/Transportation
        </a>
      </li>
      <li>
        <a href="#" title="Banking/Financial Services">
          Banking/Financial Services
        </a>
      </li>
      <li>
        <a href="#" title="Education/Training">
          Education/Training
        </a>
      </li>
      <li>
        <a href="#" title="Health & Fitness">
          Health &amp; Fitness
        </a>
      </li>
    </>
  );
};

export default PopularIndustry;
