import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CandidateRegister from "./candidate-register/CandidateRegister";
import CandidateEmployer from "./candidate-employer/CandidateEmployer";

const Register = () => {
  return (
    <>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="loginInfo">
                <Tabs
                  defaultActiveKey="candidate"
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="candidate" title="Candidate">
                    <CandidateRegister />
                  </Tab>
                  <Tab eventKey="employer" title="Employer">
                    <CandidateEmployer /> 
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="loginImage">
                <img
                  src="https://hsalghanimdelivery.com/images/login-page-banner.png"
                  alt="Login"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
