import React from "react";
import { Link } from "react-router-dom";
import {BiChevronRight} from "react-icons/bi"

const Team = () => {
  return (
    <>
      <section className="sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="teamContent">
                <p>
                  If you are ready to shine, we are ready to make it happen
                  through our world class career services such as:
                </p>
                <ul>
                  <li>
                    <Link to="/"><BiChevronRight />  Career Planning & Assessment</Link>
                  </li>
                  <li>
                    <Link to="/"><BiChevronRight />  21st Century Skills </Link>
                  </li>
                  <li>
                    <Link to="/"><BiChevronRight />  Employability Training </Link>
                  </li>
                  <li>
                    <Link to="/"><BiChevronRight />  Executive Image consulting </Link>
                  </li>
                  <li>
                    <Link to="/"><BiChevronRight />  Massive Open Online Courses</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
                <div className="teamImg">
                    <img src="https://eduskill.org/images/22.jpg" alt="Team" className="img-fluid" />
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
