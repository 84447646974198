import React, { useEffect } from "react";
import Aside from "../aside/Aside";
import Job from "./job/Job";
import PageDataLength from "./page-data-length/PageDataLength";

const Jobs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main sectionPD">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="aside">
                <Aside />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="jobsDetail">
                <PageDataLength />
                <div className="searchList">
                  <Job />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Jobs;
