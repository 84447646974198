import React from "react";

const PostJob = () => {
  return (
    <>
      <section className="getStarted postJob sectionPD">
        <div className="container">
          <div className="getStart">
            <h6>Are You Looking For Candidates! </h6>
            <h2>Post a Job Today</h2>
            <strong>and hire the right Candidates</strong>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nunc ex, maximus vel felis ut, vestibulum tristique enim. Proin eu nulla est. Maecenas tempor euismod suscipit. Sed at libero ante. Vestibulum nec odio lacus.


            </p>
            <button className="btn pageBtn">Post a Job</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostJob;
