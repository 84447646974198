import React from "react";
import LatestJob from "./latest-job/LatestJob";

const LatestJobs = () => {
  return (
    <>
      <section className="sectionPD greyBg">
        <div className="container">
          <div className="row">
            <div className="sectionTitle">
              <h2>
                Latest <span>Jobs</span>
              </h2>
            </div>
            <LatestJob />
          </div>
          <div className="text-center mt-4">
            <button className="btn pageBtn">View All Latest Jobs</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestJobs;
