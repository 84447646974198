import React from "react";
import ContactAddress from "./contact-address/ContactAddress";
import ContactForm from "./contact-form/ContactForm";

const Contact = () => {
  return (
    <>
      <section className="sectionPD">
        <div className="container">
          <div className="contactTitle">
            <span>We Are Here For Your Help</span>
            <h2>GET IN TOUCH FAST</h2>
            <p>
              Vestibulum at magna tellus. Vivamus sagittis nunc aliquet. Vivamin
              orci aliquam eros vel saphicula. Donec eget ultricies
              ipsmconsequat
            </p>
          </div>
          <ContactAddress />
          <ContactForm />
        </div>
      </section>
    </>
  );
};

export default Contact;
