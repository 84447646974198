import React, { useEffect } from 'react'
import Jobs from '../../components/jobs/Jobs'
import PageSearch from '../../components/page-search/PageSearch'

const JobsPage = ({setCommon}) => {
  useEffect(()=>{
    setCommon(true)
  }, [])
  return (
    <>
        <PageSearch />
        <Jobs />
    </>
  )
}

export default JobsPage