import React from "react";

const JobShift = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Job Shift</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_shift_1" />
            First Shift (Day) <span>12</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_shift_2" />
            Second Shift (Afternoon) <span>4</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_shift_3" />
            Third Shift (Night) <span>2</span>{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="job_shift_4" />
            Rotating <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">
          View More
        </button>{" "}
      </div>
    </>
  );
};

export default JobShift;
