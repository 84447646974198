import React from "react";

const ContactForm = () => {
  return (
    <>
      <div className="contactFormSec">
        <div className="row">
          <div className="col-lg-4">
            <div className="contactMap">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d224283.63882101112!2d77.25971!3d28.556792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1680162219058!5m2!1sen!2sin" width="100%" height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

            </div>
          </div>
          <div className="col-lg-8">
            <form className="row contactForm">
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-3 contactField">
                  <label htmlFor="fullname">Full Name</label>
                  <input
                    type="text"
                    name="fullname"
                    placeholder="John Doe"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-3 contactField">
                  <label htmlFor="mail">Enter Email</label>
                  <input
                    type="mail"
                    name="mail"
                    placeholder="exampe@gmail.com"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-3 contactField">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="number"
                    name="phone"
                    placeholder="+91 9876543210"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-3 contactField">
                  <label htmlFor="mail">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject..."
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3 contactField">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    cols="30"
                    rows="7"
                    className="form-control"
                    placeholder="Please enter your message"
                  ></textarea>
                </div>
                <div className="form-group mb-3 contactField">
                  <button type="button" className="btn pageBtn">
                    submit now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
