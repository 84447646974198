import React from "react";
import WorkStep from "./work-step/WorkStep";

const HowItWorks = () => {
  return (
    <>
      <section className="howWorks sectionPD">
        <div className="container">
          <div className="row">
            <div className="sectionTitle text-center">
              <h2>
                How It <span>Works</span>
              </h2>
            </div>
          

          <WorkStep />
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
