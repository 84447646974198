import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarker } from "react-icons/fa";
import { MdEmail, MdCall } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <footer className="footerSec sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footerInfo">
                <h5>Quick Links</h5>
                <ul className="links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms Of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footerInfo">
                <h5>Jobs By Functional Area</h5>
                <ul className="links">
                  <li>
                    <Link to="/">Graphic Design</Link>
                  </li>
                  <li>
                    <Link to="/">Medicine</Link>
                  </li>
                  <li>
                    <Link to="/">Advertising</Link>
                  </li>
                  <li>
                    <Link to="/">Marketing</Link>
                  </li>
                  <li>
                    <Link to="/">Admin</Link>
                  </li>
                  <li>
                    <Link to="/">Business Systems Analyst</Link>
                  </li>
                  <li>
                    <Link to="/">Software & Web Development</Link>
                  </li>
                  <li>
                    <Link to="/">Bank Operation</Link>
                  </li>
                  <li>
                    <Link to="/">Database Administration (DBA)</Link>
                  </li>
                  <li>
                    <Link to="/">Web Developer</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footerInfo">
                <h5>Jobs By Industry</h5>
                <ul className="links">
                  <li>
                    <Link to="/">Information Technology</Link>
                  </li>
                  <li>
                    <Link to="/">AutoMobile</Link>
                  </li>
                  <li>
                    <Link to="/">Hospitality</Link>
                  </li>
                  <li>
                    <Link to="/">Advertising/PR</Link>
                  </li>
                  <li>
                    <Link to="/">Courier/Logistics</Link>
                  </li>
                  <li>
                    <Link to="/">Electronics</Link>
                  </li>
                  <li>
                    <Link to="/">Education/Training</Link>
                  </li>
                  <li>
                    <Link to="/">Travel/Tourism/Transportation</Link>
                  </li>
                  <li>
                    <Link to="/">Banking/Financial Services</Link>
                  </li>
                  <li>
                    <Link to="/">Health & Fitness</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footerInfo">
                <h5>Contact Us</h5>
                <ul className="address">
                  <li>
                    <div className="icon">
                      <MdEmail />
                    </div>
                    <div className="content">
                      <a href="mailto:info@jobs.ind.do">
                        info@jobs.ind.do
                      </a>
                    </div>
                  </li>

                  <li>
                    <div className="icon">
                      <MdCall />
                    </div>
                    <div className="content">
                      <a href="call:+918851746286">+918851746286</a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FaMapMarker />
                    </div>
                    <div className="content">
                      <p>
                        Saket, New Delhi-110020, office No23 Website
                      
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="footerInfo">
                <h5>About us</h5>
                <p>
                  Established in 2013, Eduskill is one of India's fastest
                  growing tech-driven training companies that enables academic
                  Institutions, Corporate Houses and Individuals to improve
                  knowledge enrichment and skill enhancement with new-age
                  technologies.. <Link to="/" className="readMore">read more</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyRight">
        <div className="container">
          <div className="copyRightInfo">
            <p>
              Copyright &#169; 2023 Edu Skills. All
              Rights Reserved. Powered by: <Link to="https://www.abarissoftech.com" target="_blank">Abaris Softech</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
