import React from "react";
import { Link } from "react-router-dom";

const NoticeBoard = ({ handleClick, removeModal, handleChild }) => {
  return (
    <>
      <>
        <div className="noticeModal" onClick={removeModal}> 
          <div className="modal-dialog noticeBoard" onClick={handleChild}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 mb-4" id="exampleModalLabel">
                  Notice Board
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClick}
                />
              </div>
              <div className="modal-body">
                <div className="notice">
                  <h4>Candidate</h4>
                  <p>
                    We help you to embark on your dream job with prominent
                    companies and support to build your rewarding career
                  </p>
                  <div className="readMoreBtn">
                    <Link to="/">read more</Link>
                  </div>
                </div>

                <div className="notice">
                  <h4>Recruiter</h4>
                  <p>
                    Hire the best Entry Level Talent utilizing our sizable
                    campuses & students network and seize the promising
                    graduates
                  </p>
                  <div className="readMoreBtn">
                    <Link to="/">read more</Link>
                  </div>
                </div>
                <div className="text-center">
                  <button type="button" className="btn pageBtn">
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default NoticeBoard;
