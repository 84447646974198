import React from "react";
import { Link } from "react-router-dom";

const JobCity = () => {
  return (
    <>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/durant-1664554604-420.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
              <a href="https://hsalghanimdelivery.com/jobs?city_id%5B%5D=45830">
                Durant
              </a>
            </h4>
            <span>(2) Open Jobs</span>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/arnold-1664554692-671.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
              <Link to="/">
                Durant
              </Link>
            </h4>
            <span>(2) Open Jobs</span>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/bessemer-1664554631-197.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
            <Link to="/">
              Bessemer
              </Link>
            </h4>
            <span>(2) Open Jobs</span>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/alabaster-1664557698-668.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
            <Link to="/">
              Alabaster
              </Link>
            </h4>
            <span>(2) Open Jobs</span>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/milton-1664557745-615.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
            <Link to="/">
              Milton
              </Link>
            </h4>
            <span>(2) Open Jobs</span>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/clarksville-1664554652-830.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
            <Link to="/">
              Clarksville
              </Link>
            </h4>
            <span>(1) Open Jobs</span>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="cityInfoBox">
          <div className="cityImg">
            <img
              src="https://hsalghanimdelivery.com/city_images/kaneohe-station-1664554672-406.jpg"
              className="img-fluid"
              alt="City"
            />
          </div>
          <div className="cityText">
            <h4>
            <Link to="/">
              Kaneohe Station
              </Link>
            </h4>
            <span>(2) Open Jobs</span>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default JobCity;
