import React from "react";
import { BsFillBuildingFill } from "react-icons/bs";

const CompanyOverview = () => {
  return (
    <>
      <div className="jbDetailBox">
        <div className="jbDetailHeader company">
          <h5>
            <BsFillBuildingFill /> Company Overview
          </h5>
          <div className="companyOverview">
            <div className="companyHeader">
              <div className="logo">
                <a href="#">
                  <img
                    src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                    alt="Logo"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="content">
                <h4 className="companyName">Abaris Softech Pvt Ltd</h4>
                <h6 className="location">New Delhi, Delhi, India</h6>
              </div>
            </div>
            <div className="openingText">
              <a href="#">6 Current Jobs Openings</a>
            </div>
            <div className="companyFooter">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                fermentum condimentum mauris, non posuere urna consectetur quis.
                Suspendisse semper eu eros eget convallis. Etiam mattis blandit
                nulla, non venenatis risus varius vel. Morbi fringilla dig...{" "}
                <a className="readMore" href="#">read more</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyOverview;
