import React from "react";

const JobsByCity = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By City</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_42602" />
            Auburn <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_43593" />
            Aventura <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_43396" />
            Canon City <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_43929" />
            Atlanta <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_42594" />
            Alabaster <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_43579" />
            Milton <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_44403" />
            Clarksville <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_25833" />
            ad-Dawhah <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_45830" />
            Durant <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_44078" />
            Kaneohe Station <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_44755" />
            Arnold <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_42762" />
            El Dorado <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_46777" />
            Blaine <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_25787" />
            al-Ahmadi <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_42603" />
            Bessemer <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_42695" />
            Casas Adobes <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="city_41174" />
            Tonga <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">View More</button>{" "}
      </div>
    </>
  );
};

export default JobsByCity;
