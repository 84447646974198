import React, { useEffect } from "react";
import Contact from "../../components/contact/Contact";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";

const ContactPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(true);
  }, []);
  return (
    <>
      <Breadcrumb title="contact" />
      <Contact />
    </>
  );
};

export default ContactPage;
