import React from "react";
import BlogCategory from "./blog-category/BlogCategory";

const BlogCategories = () => {
  return (
    <>
      <div className="blogAsideBox">
        <h5>Categories</h5>
        <BlogCategory />
      </div>
    </>
  );
};

export default BlogCategories;
