import React, { useEffect } from "react";
import Blogs from "../../components/blogs/Blogs";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";

const BlogPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(true);
  }, []);
  return (
    <>
      {/* <div className="sectionPD">
        <div className="container">
          <div className="page d-flex align-items-center justify-content-between">
            <h6>Blog Page not found</h6>
            <Link to="/" className="btn pageBtn">
              <HiArrowNarrowLeft /> Back to home page
            </Link>
          </div>
        </div>
      </div> */}
      <Breadcrumb title="blog" />
      <Blogs />
    </>
  );
};

export default BlogPage;
