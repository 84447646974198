import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { AiFillFileText } from "react-icons/ai";

const JobBenefits = () => {
  return (
    <>
      <div className="jbDetailBox">
        <div className="jbDetailHeader benefits">
          <h5>
            <AiFillFileText /> Benefits
          </h5>
          <ul>
            <li>
              <BiChevronRight />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <BiChevronRight />
              Fusce id diam vitae lacus consectetur placerat.
            </li>
            <li>
              <BiChevronRight />
              Nulla consequat metus ac ante semper vehicula.
            </li>
            <li>
              <BiChevronRight />
              Vivamus eleifend elit ut porta hendrerit.
            </li>
            <li>
              <BiChevronRight />
              Donec suscipit magna eu sapien elementum, sit amet ornare ipsum
              consequat.
            </li>
            <li>
              <BiChevronRight />
              Etiam faucibus nisl accumsan commodo fringilla.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default JobBenefits;
