import React from "react";
import LatestBlog from "./latest-blog/LatestBlog";

const LatestBlogs = () => {
  return (
    <>
      <section className="latestBlog sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>Here You Can See</h6>
                <h2>
                  Latest <span>Blogs</span>
                </h2>
              </div>
            </div>
            <LatestBlog />
            <div className="text-center">
              <button type="button" className="btn pageBtn">View All Blog Posts</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlogs;
