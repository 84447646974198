import React from "react";

const RelatedJob = () => {
  return (
    <>
      <div className="relatedJobInfo">
        <h6>
          <a href="#">Graphic Designer Required</a>
        </h6>
        <div className="companyName">
          <a href="#" title="Abaris Softech Pvt Ltd">
            Abaris Softech Pvt Ltd
          </a>
        </div>
        <div className="location">
          <span>Bessemer</span>
        </div>
        <div className="location">
          <label className="fulltime">Contract</label>
          <label className="partTime">Second Shift (Afternoon)</label>{" "}
        </div>
      </div>

      <div className="relatedJobInfo">
        <h6>
          <a href="#">Full Stack Developer Required</a>
        </h6>
        <div className="companyName">
          <a href="#" title="Abaris Softech Pvt Ltd">
            Abaris Softech Pvt Ltd
          </a>
        </div>
        <div className="location">
          <span>Bessemer</span>
        </div>
        <div className="location">
          <label className="fulltime">Contract</label>
          <label className="partTime">Second Shift (Afternoon)</label>{" "}
        </div>
      </div>
    </>
  );
};

export default RelatedJob;
