import React from "react";

const CompanyDetailInfo = () => {
  return (
    <>
      <div className="jbDetailHeader company">
        <h5>Company Detail</h5>
        <ul className="jbDetailList">
          <li>
            <div className="property">
              <h6>Is Email Verified:</h6>
            </div>
            <div className="value">
              <span>No</span>
            </div>
          </li>
          <li>
            <div className="property">
              <h6>Total Employees:</h6>
            </div>
            <div className="value">
              <span>301-600</span>
            </div>
          </li>
          <li>
            <div className="property">
              <h6>Established In:</h6>
            </div>
            <div className="value">
              <span>2002</span>
            </div>
          </li>
          <li>
            <div className="property">
              <h6>Current jobs:</h6>
            </div>
            <div className="value">
              <span>1</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CompanyDetailInfo;
