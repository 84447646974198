import React from "react";
import ClientSays from "./client-says/ClientSays";

const Testimonial = () => {
  return (
    <>
      <section className="testimonial sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h5>Testimonials</h5>
                <h2>Success <span>Stories</span></h2>
              </div>
            </div>
            <ClientSays />
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
