import React from "react";
import JobCity from "./job-city/JobCity";

const JobsCities = () => {
  return (
    <>
      <section className="jobsCities sectionPD greyBg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>Jobs by Cities</h2>
              </div>
            </div>
            <JobCity />
          </div>
        </div>
      </section>
    </>
  );
};

export default JobsCities;
