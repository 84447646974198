import React from "react";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

const BlogCategory = () => {
  return (
    <>
      <ul className="categoryList">
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> Categories
          </Link>
        </li>
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> Jobseekers
          </Link>
        </li>
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> Employers
          </Link>
        </li>
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> General
          </Link>
        </li>
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> Web Designer
          </Link>
        </li>
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> Web Developer
          </Link>
        </li>
        <li>
          <Link to="#">
            {" "}
            <BiChevronRight /> Generative Modeling Review
          </Link>
        </li>
      </ul>
    </>
  );
};

export default BlogCategory;
