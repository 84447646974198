import React from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const JobOpenings = () => {
  return (
    <>
      <div className="jobOpening">
        <h4>Job Openings</h4>
        <div className="featuredJob">
          <div className="row">
            <div className="col-lg-9 col-md-9">
              <div className="featuredDetail">
                <div className="jobImg">
                  <img
                    src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                    alt="Hotel Tirupati Classic"
                    className="img-fluid"
                  />
                </div>
                <div className="featuredJobText">
                  <h5>
                    <Link to="/job-detail">Marketing Manager Required</Link>
                    <BsFillLightningChargeFill />
                  </h5>
                  <div className="companyName">
                    <a href="#" title="Hotel Tirupati Classic">
                      Hotel Tirupati Classic
                    </a>
                  </div>
                  <div className="location">
                    <label className="fulltime" title="Part Time">
                      Part Time
                    </label>
                    <label
                      className="partTime"
                      title="Second Shift (Afternoon)"
                    >
                      Second Shift (Afternoon)
                    </label>
                     <span className="d-inline-block ml-3">Clarksville</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="listBtn">
                <Link to="/job-detail" className="btn pageBtn viewDetail">
                  View Details
                </Link>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="jobPara">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  pretium nunc non justo placerat pulvinar. Vestibulum ac
                  ullamcorper sapien, nec scelerisq...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobOpenings;
