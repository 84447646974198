import React from "react";
import {FiSearch} from "react-icons/fi"

const PageSearch = () => {
  return (
    <>
      <div className="pageSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h4 className="mb-3">
                One million success job stories. <span>Start yours today.</span>
              </h4>
              <div className="searchForm">
                <div className="input-group">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="Enter Skills or job title"
                    autoComplete="off"
                  />
                  <button type="button" className="btn searchBtn">
                    <FiSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSearch;
