import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsBriefcaseFill } from "react-icons/bs";
import { Link } from "react-router-dom";
const FeaturedCompany = () => {
  return (
    <>
      <div className="featuredCompany">
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/surf-wave-1536855024-252.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Surf Wave</h6>
                <p>
                  <FaMapMarkerAlt /> Kodiak
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/sphere-1536859528-893.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Sphere</h6>
                <p>
                  <FaMapMarkerAlt /> Huntington
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Mayan Design Studios</h6>
                <p>
                  <FaMapMarkerAlt /> Airmont
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 3 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/pharma-tech-inc-1536858973-298.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Pharma Tech Inc.</h6>
                <p>
                  <FaMapMarkerAlt /> New York
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 1 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Hotel Tirupati Classic</h6>
                <p>
                  <FaMapMarkerAlt /> al-Ahmadi
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/travel-advisor-1536859060-458.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Travel Adviser</h6>
                <p>
                  <FaMapMarkerAlt /> Andrews
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Abaris Softech</h6>
                <p>
                  <FaMapMarkerAlt /> New Delhi
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/surf-wave-1536855024-252.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Surf Wave</h6>
                <p>
                  <FaMapMarkerAlt /> Kodiak
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/surf-wave-1536855024-252.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Surf Wave</h6>
                <p>
                  <FaMapMarkerAlt /> Kodiak
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>New Design Studio</h6>
                <p>
                  <FaMapMarkerAlt />  Atlantic City
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/sphere-1536859528-893.jpg"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Sphere</h6>
                <p>
                  <FaMapMarkerAlt /> Huntington
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 6 Open Jobs
              </p>
            </div>
          </Link>
        </div>
        <div className="featuredBox">
          <Link to="/company-detail">
            <div className="comHeader">
              <div className="comLogo">
                <img
                  src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                  alt="Company"
                />
              </div>
              <div className="comText">
                <h6>Mayan Design Studios</h6>
                <p>
                  <FaMapMarkerAlt /> Airmont
                </p>
              </div>
            </div>
            <div className="comFooter">
              <p className="brief">
                <BsBriefcaseFill /> 3 Open Jobs
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default FeaturedCompany;
