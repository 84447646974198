import React from "react";
import { FaBell } from "react-icons/fa";
import { BsFillFileTextFill } from "react-icons/bs";
import JobsByCountry from "./jobs-by-country/JobsByCountry";
import JobsByTitle from "./jobs-by-title/JobsByTitle";
import JobsByState from "./Jobs-by-state/JobsByState";
import JobsByCity from "./jobs-by-city/JobsByCity";
import JobsByExperience from "./jobs-by-experience/JobsByExperience";
import JobType from "./job-type/JobType";
import JobShift from "./job-shift/JobShift";
import CareerLevel from "./career-level/CareerLevel";
import JobsByGender from "./jobs-by-gender/JobsByGender";
import JobsByIndustry from "./jobs-by-industry/JobsByIndustry";
import JobsBySkill from "./jobs-by-skill/JobsBySkill";
import JobsByFunctionalAreas from "./jobs-by-functional-areas/JobsByFunctionalAreas";
import JobsByCompany from "./jobs-by-company/JobsByCompany";
import SalaryRange from "./salary-range/SalaryRange";

const Aside = () => {
  return (
    <>
      <div className="jobReqBtn">
        <div className="btn btn-job-alert">
          <FaBell /> Save Job Alert
        </div>
        <div className="btn inputFile">
          <BsFillFileTextFill /> 
          {/* <input type="file" /> */}
          Upload Your
          Resume
        </div>
      </div>

      <div className="sidebar">
        <JobsByTitle />
        <JobsByCountry />
        <JobsByState />
        <JobsByCity />
        <JobsByExperience />
        <JobType />
        <JobShift />
        <CareerLevel />
        <JobsByGender />
        <JobsByIndustry />
        <JobsBySkill />
        <JobsByFunctionalAreas />
        <JobsByCompany />
        <SalaryRange />
      </div>
    </>
  );
};

export default Aside;
