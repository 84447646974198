import React from "react";

const LatestJob = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Marketing Manager Required"
              >
                Marketing Manager Required
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company/hotel-tirupati-classic-1"
                title="Hotel Tirupati Classic"
              >
                Hotel Tirupati Classic
              </a>{" "}
              - <span>Tonga</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Full Time/Permanent">
                Full Time/Permanent
              </label>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Web Designer"
              >
                Web Designer
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                New Design Studio
              </a>{" "}
              - <span>Milton</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Internship">
                Internship
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="SEO Expert"
              >
                SEO Expert
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                Abaris Softech Pvt Ltd
              </a>{" "}
              - <span>Casas Adobes</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Full Time/Permanent">
                Full Time/Permanent
              </label>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Graphic Designer Required"
              >
                Graphic Designer Required
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                Abaris Softech Pvt Ltd
              </a>{" "}
              - <span>Bessemer</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Contract">
                Contract
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Full Stack Developer Required"
              >
                Full Stack Developer Required
              </a>
            </h6>
            <div className="company">
              <a href="#" title="Abaris Softech Pvt Ltd">
                Abaris Softech Pvt Ltd
              </a>{" "}
              - <span>Bessemer</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Contract">
                Contract
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Full Stack Designer"
              >
                Full Stack Designer
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                Abaris Softech Pvt Ltd
              </a>{" "}
              - <span>Casas Adobes</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Full Time/Permanent">
                Full Time/Permanent
              </label>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/power-wave-1536854843-603.jpg"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Dot Developer"
              >
                Dot Developer
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                Power Wave
              </a>{" "}
              - <span>Durant</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Contract">
                Contract
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/media-wave-1536851777-515.jpg"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Graphic Designer"
              >
                Graphic Designer
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                Media Wave
              </a>{" "}
              - <span>Arnold</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Freelance">
                Freelance
              </label>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="latestJobInfo">
          <div className="companyImg">
            <a href="#">
              <img
                src="https://hsalghanimdelivery.com/company_logos/sphere-1536859528-893.jpg"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </a>
          </div>
          <div className="companyText">
            <h6>
              <a
                href="https://hsalghanimdelivery.com/job/link-34"
                title="Frontend Developer"
              >
                Frontend Developer
              </a>
            </h6>
            <div className="company">
              <a
                href="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                title="Abaris Softech Pvt Ltd"
              >
                Sphere
              </a>{" "}
              - <span>Clarksville</span>
            </div>
            <div className="jobloc">
              <label className="fulltime" title="Part Time">
                Part Time
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestJob;
