import React from "react";

const CareerLevel = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Career Level</h4>
        <ul className="optionList view_more_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="career_level_3" />
            Experienced Professional <span />{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="career_level_2" />
            Entry Level <span />{" "}
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="career_level_1" />
            Department Head <span />{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">
          View More
        </button>{" "}
      </div>
    </>
  );
};

export default CareerLevel;
