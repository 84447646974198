import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillBriefcaseFill } from "react-icons/bs";

const FeaturedJob = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="featuredJobInfo">
          <div className="featuredJobHeader">
            <span className="icon">
                <BsFillBriefcaseFill />
            </span>
            <p> Full Time/Permanent</p>
          </div>
          <div className="featuredJobBody">
            <h5>
              <a href="#">Marketing Manager Required</a>
            </h5>
            <strong>
              <FaMapMarkerAlt /> Tonga
            </strong>
          </div>
          <div className="featuredJobFooter">
            <div className="companyDetail">
              <p>Oct 20, 2020</p>
              <a href="#">Hotel Tirupati Classic</a>
            </div>
            <div className="companyLogo">
              <img
                src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="featuredJobInfo">
          <div className="featuredJobHeader">
            <span className="icon">
                <BsFillBriefcaseFill />
            </span>
            <p> Full Time/Permanent</p>
          </div>
          <div className="featuredJobBody">
            <h5>
              <a href="#">Full Stack Designer</a>
            </h5>
            <strong>
              <FaMapMarkerAlt /> al-Ahmadi
            </strong>
          </div>
          <div className="featuredJobFooter">
            <div className="companyDetail">
              <p>Sep 18, 2018</p>
              <a href="#">Abaris Softech Pvt Ltd</a>
            </div>
            <div className="companyLogo">
              <img
                src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                alt="Abaris Softech Pvt Ltd"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="featuredJobInfo">
          <div className="featuredJobHeader">
            <span className="icon">
                <BsFillBriefcaseFill />
            </span>
            <p> Contract </p>
          </div>
          <div className="featuredJobBody">
            <h5>
              <a href="#">Dot Developer</a>
            </h5>
            <strong>
              <FaMapMarkerAlt />  Durant
            </strong>
          </div>
          <div className="featuredJobFooter">
            <div className="companyDetail">
              <p>Sep 18, 2018</p>
              <a href="#">Power Wave</a>
            </div>
            <div className="companyLogo">
              <img
                src="https://hsalghanimdelivery.com/company_logos/power-wave-1536854843-603.jpg"
                alt="Power Wave"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="featuredJobInfo">
          <div className="featuredJobHeader">
            <span className="icon">
                <BsFillBriefcaseFill />
            </span>
            <p> Full Time/Permanent</p>
          </div>
          <div className="featuredJobBody">
            <h5>
              <a href="#">Full Stack Designer</a>
            </h5>
            <strong>
              <FaMapMarkerAlt /> al-Ahmadi
            </strong>
          </div>
          <div className="featuredJobFooter">
            <div className="companyDetail">
              <p>Sep 18, 2018</p>
              <a href="#">Abaris Softech Pvt Ltd</a>
            </div>
            <div className="companyLogo">
              <img
                src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                alt="Abaris Softech Pvt Ltd"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="featuredJobInfo">
          <div className="featuredJobHeader">
            <span className="icon">
                <BsFillBriefcaseFill />
            </span>
            <p> Contract </p>
          </div>
          <div className="featuredJobBody">
            <h5>
              <a href="#">Dot Developer</a>
            </h5>
            <strong>
              <FaMapMarkerAlt />  Durant
            </strong>
          </div>
          <div className="featuredJobFooter">
            <div className="companyDetail">
              <p>Sep 18, 2018</p>
              <a href="#">Power Wave</a>
            </div>
            <div className="companyLogo">
              <img
                src="https://hsalghanimdelivery.com/company_logos/power-wave-1536854843-603.jpg"
                alt="Power Wave"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="featuredJobInfo">
          <div className="featuredJobHeader">
            <span className="icon">
                <BsFillBriefcaseFill />
            </span>
            <p> Full Time/Permanent</p>
          </div>
          <div className="featuredJobBody">
            <h5>
              <a href="#">Marketing Manager Required</a>
            </h5>
            <strong>
              <FaMapMarkerAlt /> Tonga
            </strong>
          </div>
          <div className="featuredJobFooter">
            <div className="companyDetail">
              <p>Oct 20, 2020</p>
              <a href="#">Hotel Tirupati Classic</a>
            </div>
            <div className="companyLogo">
              <img
                src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                alt="Hotel Tirupati Classic"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedJob;
