import React from "react";

const JobsByFunctionalAreas = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By Functional Areas</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_128" />
            Software &amp; Web Development <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_32" />
            Electronics Technician <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_144" />
            Web Developer <span>2</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_23" />
            Creative Design <span>3</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_28" />
            Database Administration (DBA) <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_8" />
            Advertising <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_14" />
            Business Management <span>3</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_12" />
            Bank Operation <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_40" />
            Graphic Design <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_74" />
            Medicine <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_69" />
            Marketing <span>1</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_3" />
            Admin <span>2</span>
          </li>
          <li>
            <input type="checkbox" className="form-check-input" />
            <label htmlFor="functional_area_id_15" />
            Business Systems Analyst <span>1</span>
          </li>
        </ul>
        {/* title end */}{" "}
        <button type="button" className="btn view_more">
          View More
        </button>{" "}
      </div>
    </>
  );
};

export default JobsByFunctionalAreas;
