import React from "react";
import {BsBriefcase} from "react-icons/bs";
import {HiOutlineUsers} from "react-icons/hi";
import {TbBuilding} from "react-icons/tb";

const StatsBox = () => {
  return (
    <>
      <div className="statsBox">
        <div className="row">
          <div className="col">
            <div className="statsInfo">
              <div className="statsIcon">
                <BsBriefcase />
              </div>
              <div className="statsText">
                <h4>19</h4>
                <p>Active Jobs</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="statsInfo ">
              <div className="statsIcon">
                <TbBuilding />
              </div>
              <div className="statsText">
                <h4>15 </h4>
                <p>Active Companies </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="statsInfo">
              <div className="statsIcon">
                <HiOutlineUsers />
              </div>
              <div className="statsText">
                <h4>4</h4>
                <p>Candidates</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsBox;
