import React from 'react';
import {AiFillStar} from "react-icons/ai"

const RatingUser = () => {
  return (
    <>
        <div className="ratingUser">
            <ul>
                <li>
                    <AiFillStar />
                </li>
                <li>
                    <AiFillStar />
                </li>
                <li>
                    <AiFillStar />
                </li>
                <li>
                    <AiFillStar />
                </li>
                <li>
                    <AiFillStar />
                </li>                
            </ul>
        </div>
    </>
  )
}

export default RatingUser