import React from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Job = () => {
  return (
    <>
      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                  <Link to="/job-detail">Marketing Manager Required</Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#" title="Hotel Tirupati Classic">
                    Hotel Tirupati Classic
                  </a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Full Time/Permanent">
                    Full Time/Permanent
                  </label>
                  <span className="d-inline ml-2">Tonga</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
              <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                  <Link to="/job-detail">SEO Expert</Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Full Time/Permanent">
                    Full Time/Permanent
                  </label>
                  <span className="d-inline ml-2">Casas Adobes</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listbtn">
              <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Abaris Softech Pvt Ltd"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                  <Link to="/job-detail">Graphic Designer Required</Link>
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Contract">
                    Contract
                  </label>
                  <span className="d-inline ml-2"> Bessemer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
              <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                  <Link to="/job-detail">Full Stack Developer Required</Link>
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Contract">
                    Contract
                  </label>
                  <span className="d-inline ml-2"> Bessemer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
              <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail" >Full Stack Designer </Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Full Time/Permanent">
                    Full Time/Permanent
                  </label>
                  <span className="d-inline ml-2"> al-Ahmadi</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
            <Link to="/job-detail"  className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/power-wave-1536854843-603.jpg"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail" >Dot Developer </Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Contract">
                    Contract
                  </label>
                  <span className="d-inline ml-2"> Bessemer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
            <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/hotel-tirupati-classic-1670425518-257.png"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail" >Marketing Manager Required</Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a
                    href="https://hsalghanimdelivery.com/company/hotel-tirupati-classic-1"
                    title="Hotel Tirupati Classic"
                  >
                    Hotel Tirupati Classic
                  </a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Full Time/Permanent">
                    Full Time/Permanent
                  </label>
                  <span className="d-inline ml-2">Tonga</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listbtn">
               <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                  <a href="#">SEO Expert</a>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Full Time/Permanent">
                    Full Time/Permanent
                  </label>
                  <span className="d-inline ml-2">Casas Adobes</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listbtn">
               <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Abaris Softech Pvt Ltd"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail">Graphic Designer Required</Link>
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Contract">
                    Contract
                  </label>
                  <span className="d-inline ml-2"> Bessemer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
               <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail">Full Stack Developer Required</Link>
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Contract">
                    Contract
                  </label>
                  <span className="d-inline ml-2"> Bessemer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
               <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail">Full Stack Designer </Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Full Time/Permanent">
                    Full Time/Permanent
                  </label>
                  <span className="d-inline ml-2"> al-Ahmadi</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
               <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="featuredJob">
        <div className="row">
          <div className="col-lg-10 col-md-8">
            <div className="featuredDetail">
              <div className="jobImg">
                <img
                  src="https://hsalghanimdelivery.com/company_logos/power-wave-1536854843-603.jpg"
                  alt="Hotel Tirupati Classic"
                  className="img-fluid"
                />
              </div>
              <div className="featuredJobText">
                <h5>
                <Link to="/job-detail">Dot Developer </Link>
                  <BsFillLightningChargeFill />
                </h5>
                <div className="companyName">
                  <a href="#">Abaris Softech Pvt Ltd</a>
                </div>
                <div className="location">
                  <label className="fulltime" title="Contract">
                    Contract
                  </label>
                  <span className="d-inline ml-2"> Bessemer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="listBtn">
               <Link to="/job-detail" className="btn pageBtn viewDetail">
                View Details
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="jobPara">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Job;
