import React from "react";
import { FaUserAlt, FaUserTie } from "react-icons/fa";
import { AiFillFileText } from "react-icons/ai";

const WorkStep = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="workDetail">
          <FaUserAlt />
          <h5>Create An Account</h5>
          <p>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="workDetail">
          <FaUserTie />
          <h5>Search Desired Job</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incidid ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="workDetail">
          <AiFillFileText />
          <h5>Send Your Resume</h5>
          <p>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    </>
  );
};

export default WorkStep;
