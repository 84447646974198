import React from "react";
import FeaturedJob from "./featured-job/FeaturedJob";

const FeaturedJobs = () => {
  return (
    <>
      <section className="sectionPD">
        <div className="container">
          <div className="sectionTitle">
            <h2>
              Featured <span>JOBS</span>
            </h2>
          </div>
          <div className="row">
            <FeaturedJob />
          </div>
          <div className="text-center">
            <button className="btn pageBtn">view all featured jobs</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedJobs;
