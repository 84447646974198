import React, { useEffect } from "react";
import PageSearch from "../../components/page-search/PageSearch";
import TermsConditions from "../../components/terms-conditions/TermsConditions";

const TermsConditionsPage = ({ setCommon }) => {
  useEffect(() => {
    setCommon(true);
  }, []);
  return (
    <>
      <PageSearch />
      <TermsConditions />
    </>
  );
};

export default TermsConditionsPage;
