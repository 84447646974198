import React from "react";

const JobsByState = () => {
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Jobs By State</h4>
        <ul className="optionList view_more_ul hide_vm_ul">
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3919" />
            Alabama <span>4</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3930" />
            Florida <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3926" />
            Colorado <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3931" />
            Georgia <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3928" />
            Delaware <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3935" />
            Indiana <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_2091" />
            al-Kuwayt <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3960" />
            Oklahoma <span>2</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3932" />
            Hawaii <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3942" />
            Maryland <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3922" />
            Arkansas <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3975" />
            Washington <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_2088" />
            al-Ahmadi <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3921" />
            Arizona <span>1</span>{" "}
          </li>
          <li>
            <input
              type="checkbox"
              className="form-check-input"
            />
            <label htmlFor="state_3755" />
            Nanumanga <span>1</span>{" "}
          </li>
        </ul>
        <button type="button" className="btn view_more">View More</button>{" "}
      </div>
    </>
  );
};

export default JobsByState;
