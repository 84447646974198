import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ title }) => {
  return (
    <>
      <div className="pageBreadcrumb">
        <div className="container d-flex align-items-center justify-content-between">
          <h4 className="pageHeading">{title}</h4>

          <div className="breadcrumbLabel">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {title}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
