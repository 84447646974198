import React from "react";

const Subscribe = () => {
  return (
    <>
      <section className="subscribeSec sectionPD">
        <div className="container">
          <div className="subscribe">
            <div className="sectionTitle">
            <h2 className="m-0 text-white">Newsletter</h2>
            <p className="text-white">Subscribe to our newsletter and stay updated.</p>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-8">
                <div id="alert_messages" />
                <form className="subsForm">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      required
                      autoComplete="off"
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      required
                      autoComplete="off"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn pageBtn"
                        type="button"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscribe;
