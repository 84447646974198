import React, { useEffect, useState } from "react";
import FeaturedCompanies from "../featured-companies/FeaturedCompanies";
import FeaturedJobs from "./featured-jobs/FeaturedJobs";
import GetStarted from "./get-started/GetStarted";
import HowItWorks from "./how-it-works/HowItWorks";
import JobVideo from "./job-video/JobVideo";
import JobsCities from "./jobs-cities/JobsCities";
import LatestBlogs from "./latest-blogs/LatestBlogs";
import LatestJobs from "./latest-jobs/LatestJobs";
import NoticeBoard from "./notice-board/NoticeBoard";
import PopularSearch from "./popular-search/PopularSearch";
import PostJob from "./post-job/PostJob";
import SearchBanner from "./search-banner/SearchBanner";
import Subscribe from "./subscribe/Subscribe";
import Team from "./team/Team";
import Testimonial from "./testimonial/Testimonial";

const Home = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClick = () => {
    setShow(false);
  };
  const removeModal = () => {
    setShow(false);
  };
  const handleChild = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      {show && (
        <NoticeBoard
          handleClick={handleClick}
          removeModal={removeModal}
          handleChild={handleChild}
        />
      )}
      <SearchBanner />
      <FeaturedCompanies />
      <PopularSearch />
      <FeaturedJobs />
      <GetStarted />
      <HowItWorks />
      <LatestJobs />
      <Team />
      <Testimonial />
      <JobsCities />
      <JobVideo />
      <PostJob />
      <LatestBlogs />
      <Subscribe />
    </>
  );
};

export default Home;
