import React from "react";
import { AiFillFileText } from "react-icons/ai";

const JobInfo = () => {
  return (
    <>
      <div className="jbDetailBox">
        <div className="jbDetailHeader">
          <h5>
            <AiFillFileText /> Job Detail
          </h5>
          <ul className="jbDetailList">
            <li>
              <div className="property">
                <h6>Location:</h6>
              </div>
              <div className="value">
                <span>Tonga, Nanumanga, Tuvalu</span>
              </div>
            </li>
            <li>
              <div className="property">
                <h6>Company:</h6>
              </div>
              <div className="value">
                <a href="#">Hotel Tirupati Classic</a>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Type:</h6>
              </div>
              <div className="value">
                <span className="permanent">Full Time/Permanent</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Shift:</h6>
              </div>
              <div className="value">
                <span className="freelance">First Shift (Day)</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Career Level:</h6>
              </div>
              <div className="value">
                <span>Experienced Professional</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Positions:</h6>
              </div>
              <div className="value">
                <span>4</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Experience:</h6>
              </div>
              <div className="value">
                <span>5 Year</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Gender:</h6>
              </div>
              <div className="value">
                <span>Male</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Degree:</h6>
              </div>
              <div className="value">
                <span>Intermediate/A-Level</span>
              </div>
            </li>

            <li>
              <div className="property">
                <h6>Apply Before:</h6>
              </div>
              <div className="value">
                <span>Jun 29, 2023</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="jbButtons">
          <a href="#" className="btn">
            <i className="fas fa-envelope" aria-hidden="true" /> Email to Friend
          </a>
          <a href="#" className="btn">
            <i className="fas fa-floppy-o" aria-hidden="true" /> Add to
            Favourite
          </a>{" "}
          <a href="#" className="btn report">
            <i className="fas fa-exclamation-triangle" aria-hidden="true" />{" "}
            Report Abuse
          </a>
        </div>
      </div>
    </>
  );
};

export default JobInfo;
