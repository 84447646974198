import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Preloader = () => {
    const handleTop = () =>{
        // window.
    }
  return (
    <>
      <div className="preloader" onClick={handleTop}>
        <Spinner animation="border" />
      </div>
    </>
  );
};

export default Preloader;
