import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarker, FaHistory } from "react-icons/fa";

const CandidateInfo = () => {
  return (
    <>
      <section className="sectionPD ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="candidateSec">
                <div className="row">
                  <div className="col-lg-8 col-md-8">
                    <div className="candidateInfo">
                      <div className="userPic">
                        <a href="#">
                          <img
                            src="https://hsalghanimdelivery.com/company_logos/sphere-1536859528-893.jpg"
                            alt="Sphere"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                      <div className="userText">
                        <h4 className="title">Sphere</h4>
                        <p className="desc">Education/Training</p>
                        <div className="localTime">
                          <ul>
                            <li>
                              <FaHistory />
                              Member Since, Jul 27, 2018
                            </li>
                            <li>
                              <FaMapMarker />
                              Your Location Address USA
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="candidateLogin">
                      <p>Login to View contact details</p>
                      <Link to="/login" className="btn pageBtn">
                        Login
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="jbButtons pb-0">
                      <a href="#" className="btn">
                        <i className="fas fa-envelope" aria-hidden="true" />{" "}
                        Email to Friend
                      </a>
                      <a href="#" className="btn">
                        <i className="fas fa-floppy-o" aria-hidden="true" /> Add
                        to Favourite
                      </a>
                      <a href="#" className="btn report">
                        <i
                          className="fas fa-exclamation-triangle"
                          aria-hidden="true"
                        />
                        Report Abuse
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateInfo;
