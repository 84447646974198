import React from "react";
import { Link } from "react-router-dom";
import { BiChevronRight} from "react-icons/bi"

const PopularSearch = () => {
  return (
    <>
      <section className="sectionPD greyBg">
        <div className="container">
          <div className="sectionTitle">
            <h2>Browse Jobs By Functional Area</h2>
            <div className="srcHint">
              <ul className="cateList">
                <li>
                  <Link to="/" title="Creative Design">
                    <BiChevronRight /> Creative Design <span>(3)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Business Management">
                  < BiChevronRight /> Business Management <span>(3)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Admin">
                  < BiChevronRight />  Admin <span>(2)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Web Developer">
                  < BiChevronRight />  Web Developer <span>(2)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Graphic Design">
                  < BiChevronRight /> Graphic Design <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Medicine">
                  < BiChevronRight /> Medicine <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Marketing">
                  < BiChevronRight />  Marketing <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Business Systems Analyst">
                  < BiChevronRight />  Business Systems Analyst <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Software & Web Development">
                  < BiChevronRight /> Software &amp; Web Development <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Electronics Technician">
                  < BiChevronRight /> Electronics Technician <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Database Administration (DBA)">
                  < BiChevronRight /> Database Administration (DBA) <span>(1)</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" title="Advertising">
                  < BiChevronRight /> Advertising <span>(1)</span>
                  </Link>
                </li>
              </ul>
              {/*Categories end*/}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularSearch;
